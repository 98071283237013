import { useState, useEffect } from 'react';

const useTelegramUser = () => {
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [isUserAvailable, setIsUserAvailable] = useState(false);

    useEffect(() => {
        const checkTelegramUser = () => {
            if (!window.Telegram || !window.Telegram.WebApp) {
                setError('Please run this app through Telegram');
                setIsUserAvailable(false);
                return null;
            }
            try {
                const webApp = window.Telegram.WebApp;
                if (!webApp.initDataUnsafe || !webApp.initDataUnsafe.user) {
                    setError('Unable to access user information ! Please run this app through Telegram');
                    setIsUserAvailable(false);
                    return null;
                }
                const userInfo = webApp.initDataUnsafe.user;
                if (!userInfo.id) {
                    setError('User ID not found');
                    setIsUserAvailable(false);
                    return null;
                }
                setUser(userInfo);
                setError(null);
                setIsUserAvailable(true);
                console.log("Telegram Info:", webApp.initDataUnsafe);
                console.log("Telegram User Info:", userInfo);
                return userInfo;
            } catch (error) {
                setError('Error accessing user information');
                setIsUserAvailable(false);
                return null;
            }
        };

        const intervalId = setInterval(() => {
            checkTelegramUser();
        }, 500);
        return () => clearInterval(intervalId);
    }, []);
    return { error, user, isUserAvailable };
};
export default useTelegramUser;
