import React, { useEffect, useState } from 'react';
import '../css/intro.css';
import OrionGalacticLogo from '../components/SkyBackground';
import TypewriterEffect from '../components/TypewriterEffect';
import UserService from '../service/UserService';
import { useNavigate } from 'react-router-dom';
import useTelegramUser from '../hooks/useTelegramUser'
import { useLocation } from 'react-router-dom'
import InviteService from '../service/InviteService';

const Intro = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokensReceived, setTokensReceived] = useState(0);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const navigate = useNavigate();
  const { error: userError, user: telegramUser } = useTelegramUser()
  const location = useLocation()

  const generateRandomCoin = () => {
    const randomBytes = new Uint32Array(1);
    crypto.getRandomValues(randomBytes);
    const randomValue = (randomBytes[0] % (5000 - 2000 + 1)) + 2000;
    return randomValue;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (telegramUser) {
        const queryParams = new URLSearchParams(location.search);
        const startParam = queryParams.get('start_param');
        console.log('Received start_param:', startParam);
        if (startParam && startParam.length > 4) {
          const tgUsername = telegramUser.username;
          const tgId = telegramUser.id;
          const inviteId = startParam;
          console.log('intro ghabl az add invite:' + inviteId );
          try {
            const response = await InviteService.addInvite(tgUsername, tgId, inviteId);
            
            if (response.success) {
              console.log('Invite added successfully:', response);
              const userData = await UserService.getUserDetailsByInvitedId(inviteId);
              console.log(' user ghab az update coin' + userData.username);
              console.log('get user ghab az update coin' + userData.coin);
              console.log('get user ghab az update coin' + userData.coin + 250);
              let coin = userData.coin + 50; 
              const updatedData = { coin: coin};
              await UserService.updateUserDetailsId(updatedData , userData.id);
            
            }
  
          } catch (error) {
            console.error('Error adding invite:', error);
          }
        } else {
          console.error('Invalid start_param:', startParam);
        }
      } else {
        console.error('Invalid TelegramUser');
      }
    };
  
    fetchData();
  }, [telegramUser, location]);

  useEffect(() => {
    
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userData = await UserService.getUserDetails();
        setUser(userData);
        if (userData.intro) {
          navigate('/home-page');
        } else {
          const randomTokens = generateRandomCoin();
          console.log('random token' + randomTokens);

          setTokensReceived(randomTokens);
          const updatedData = { coin: randomTokens, intro: true };
          await UserService.updateUserDetails(updatedData);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    let timeoutId;
    if (animationCompleted) {
      timeoutId = setTimeout(() => {
        console.log('Animation completed, navigating to home.');
        navigate('/home-page');
      }, 2500); // Increased delay to ensure last text is visible
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [animationCompleted, navigate]);

  if (loading || !user) {
    return (
      <div className="app-container">
        <OrionGalacticLogo />
      </div>
    );
  }

  const texts = [
    { text: 'Welcome to Orion', color: 'white' },
    { text: 'Please wait... Building your planet', color: 'white' },
    { text: `🎉 Congratulations! You have received ${tokensReceived} tokens! 🎉`, color: 'white' }
  ];

  const handleAnimationComplete = () => {
    console.log('Animation sequence completed');
    setAnimationCompleted(true);
  };

  return (
    <div className="app-container">
      <OrionGalacticLogo />
      <div
        className="error-container"
        style={{ 
          textAlign: 'center', 
          zIndex: '9999',
          position: 'relative'
        }}
      >
        <TypewriterEffect
          texts={texts}
          speed={50}
          onComplete={handleAnimationComplete}
        />
      </div>
    </div>
  );
};

export default Intro;