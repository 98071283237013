import { supabase } from '../utils/supabaseClient'
import UserService from './UserService' // فرض بر اینکه UserService در همان مسیر قرار دارد

class InviteService {
  
  static async addInvite(tgUsername, tgId, inviteId) {
    try {
      if (!tgUsername || !tgId || !inviteId) {
        throw new Error(
          'Telegram username, Telegram ID, and invite ID are required.'
        )
      }
      console.log(inviteId);
      const user = await UserService.getUserDetailsByTgid(tgId)
      const userInvited = await UserService.getUserDetailsByInvitedId(inviteId)

      if (!user  && !userInvited) {
        throw new Error('User not found.')
      }

      console.log('tgId', inviteId)
      console.log('user.tgid', user.inviteid)
      console.log('user', user)

      if (String(inviteId) === String(user.inviteid)) {
        console.log('A user cannot invite themselves.')
        return { success: false, message: 'A user cannot invite themselves.' }
      } else {
        const { data, error } = await supabase
          .from('invites')
          .select('*')
          .eq('invited_id', tgId)
          .eq('invite_id', inviteId)
          .maybeSingle()

        if (error) {
          console.error('Error checking if invite exists:', error)
          throw new Error('Error checking if invite exists.')
        }

        if (data) {
          console.log(
            'Invite already exists for this Telegram ID and invite ID.'
          )
          return { success: false, message: 'Invite already exists.' }
        } else {
          const { error: insertError } = await supabase.from('invites').insert([
            {
              username: tgUsername,
              inviter_id: userInvited.tgid,
              invited_id: tgId,
              invite_id: inviteId,
            },
          ])

          if (insertError) {
            console.error('Error inserting invite:', insertError)
            throw new Error(insertError.message)
          }

          console.log('Invite successfully added.')
          return { success: true, message: 'Invite added successfully.' }
        }
      }
    } catch (error) {
      console.error('Error in addInvite method:', error)
      throw new Error(error.message)
    }
  }

  static async getInvitesByInviterId(inviterId) {
    try {
      if (!inviterId) {
        throw new Error('Inviter ID is required.')
      }

      const { data, error } = await supabase
        .from('invites')
        .select('*')
        .eq('invite_id', inviterId)

      if (error) {
        console.error('Error fetching invites by inviter_id:', error)
        throw new Error('Error fetching invites.')
      }

      if (data.length === 0) {
        console.log('No invites found for this inviter_id.')
        return { success: false, message: 'No invites found.' }
      }

      console.log('Invites fetched successfully:', data)
      return { success: true, data }
    } catch (error) {
      console.error('Error in getInvitesByInviterId method:', error)
      throw new Error(error.message)
    }
  }
}

export default InviteService
