import React, { useEffect, useState } from 'react'
import InviteLinkManager from '../components/InviteManager'
import '../css/invite.css'
import InviteService from '../service/InviteService'
import UserService from '../service/UserService'

const InviteFriends = () => {
  const [invites, setInvites] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchInvites = async () => {
      try {
        const userData = await UserService.getUserDetails()
        const result = await InviteService.getInvitesByInviterId(userData.inviteid)
        if (result.success) {
          setInvites(result.data)
        } else {
          setError(result.message)
        }
      } catch (err) {
        setError('Error fetching invites.')
      } finally {
        setLoading(false)
      }
    }

    fetchInvites()
  }, [])

  if (loading) {
    return (
      <div>
        <p className="invite-friends">Invite Friends</p>
        <p className="invite-friends-tips">
          SHARE YOUR INVITATION LINK & RECEIVE 50 TOKENS
        </p>
        <InviteLinkManager />
      </div>
    )
  }

  if (error) {
    console.log(error)
    return (
      <div>
        <p className="invite-friends">Invite Friends</p>
        <p className="invite-friends-tips">
          SHARE YOUR INVITATION LINK & RECEIVE 50 TOKENS
        </p>
        <InviteLinkManager />
      </div>
    )
  }

  return (
    <div>
      <p className="invite-friends">Invite Friends</p>
      <p className="invite-friends-tips">
        SHARE YOUR INVITATION LINK & RECEIVE 50 TOKENS
      </p>
      <InviteLinkManager />

      {/* بررسی طول invites. اگر بیشتر از 0 باشد دکمه‌ها نمایش داده می‌شوند */}
      {invites.length > 0 ? (
        invites.map((invite, index) => (
          <button key={index} className="generate-button-share">
            <img
              src="/assets/img/adduser.svg"
              alt="Icon"
              className="button-icon"
            />
            <span className="button-text-left">{invite.username}</span>
            <span className="button-text-right">+250</span>
          </button>
        ))
      ) : (
        <div
          style={{
            color: 'white',
            paddingLeft: '10px',
            paddingTop: '5px',
            alignContent: 'center',
          }}
        >
          😲 No invites found for you
        </div>
      )}
    </div>
  )
}

export default InviteFriends
