import React, { useState, useEffect, useCallback } from 'react'

const OrionGalacticBackground = () => {
  const [stars, setStars] = useState([])
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  // Function to get the current screen size
  const updateScreenSize = useCallback(() => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [])

  // Create stars based on screen size
  const createStars = useCallback(() => {
    const starCount = Math.max(600, Math.floor(screenSize.width * screenSize.height / 1000)) // Dynamically adjust star count
    const diagonalLength = Math.sqrt(screenSize.width ** 2 + screenSize.height ** 2)

    const newStars = []
    for (let i = 0; i < starCount; i++) {
      // Use polar coordinates to distribute stars more evenly
      const angle = Math.random() * Math.PI * 2
      const radius = Math.random() * diagonalLength / 2

      const left = screenSize.width / 2 + radius * Math.cos(angle)
      const top = screenSize.height / 2 + radius * Math.sin(angle)

      newStars.push({
        id: i,
        size: Math.random() * 2 + 1, // random size between 1 and 3
        left: `${left}px`,
        top: `${top}px`,
        animationDelay: `${Math.random() * 10}s`,
        opacity: Math.random(), // Random initial opacity
      })
    }
    setStars(newStars)
  }, [screenSize])

  const animateStars = useCallback(() => {
    setStars((prevStars) =>
      prevStars.map((star) => ({
        ...star,
        opacity: Math.random() < 0.005 ? Math.random() : star.opacity, // Subtle opacity changes
      }))
    )
    requestAnimationFrame(animateStars)
  }, [])

  useEffect(() => {
    // Create stars and start animation
    createStars()
    const animationFrame = requestAnimationFrame(animateStars)

    // Update screen size and regenerate stars on resize
    const handleResize = () => {
      updateScreenSize()
      createStars()
    }
    window.addEventListener('resize', handleResize)

    return () => {
      cancelAnimationFrame(animationFrame)
      window.removeEventListener('resize', handleResize)
    }
  }, [createStars, animateStars, updateScreenSize])

  return (
    <div className="galaxy-background">
      <div className="stars">
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              width: `${star.size}px`,
              height: `${star.size}px`,
              left: star.left,
              top: star.top,
              animationDelay: star.animationDelay,
              opacity: star.opacity,
            }}
          />
        ))}
      </div>
      <style>
        {`
          .galaxy-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #121212;
            overflow: hidden;
            z-index: -1;
          }
          .stars {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            animation: rotate 200s linear infinite;
          }
          .star {
            position: absolute;
            background-color: #fff;
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
            transition: opacity 1s ease-in-out;
          }
          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  )
}

export default OrionGalacticBackground