import '../src/css/splash.css'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import OrionGalacticLogo from './components/SkyBackground'
import useTelegramUser from './hooks/useTelegramUser'
import UserService from './service/UserService'
import { useInviteCode } from './hooks/useInviteCode'

function AppContent() {
  const { error: userError, user: telegramUser, isUserAvailable } = useTelegramUser()
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const { generateInviteCode } = useInviteCode()
  const [startParam, setStartParam] = useState(null)


  useEffect(() => {
    const initTelegramData = () => {
      try {
        if (window.Telegram?.WebApp?.initDataUnsafe) {
          const startParamFromTelegram = window.Telegram.WebApp.initDataUnsafe.start_param
          setStartParam(startParamFromTelegram || null)
        }
      } catch (error) {
        console.error('Error initializing Telegram data:', error)
      }
    }

    initTelegramData()
  }, [telegramUser])

  const handleUserAuthentication = async () => {
    try {
      if (telegramUser && isUserAvailable && telegramUser.username) {
        const username = telegramUser.id.toString()
        const password = username
        const inviteCodes = generateInviteCode(telegramUser.id)
        try {
          await UserService.signUp(
            username,
            password,
            telegramUser.first_name,
            telegramUser.username,
            telegramUser.id,
            inviteCodes,
            '',
            '0',
            false
          )
          await UserService.signIn(username, password)
          navigateToIntro()

        } catch (authError) {
          if (authError.message.includes('User already registered')) {
            try {
              await UserService.signIn(username, password)
              navigateToIntro()
            } catch (signInError) {
              setError(signInError.message || 'Login failed')
            }
          } else {
            setError(authError.message || 'Registration failed')
          }
        }
      } else {
        setError('Invalid Telegram User')
      }
    } catch (err) {
      setError(err.message || 'Authentication failed')
    }
  }

  const navigateToIntro = () => {
    if (startParam) {
      navigate(`/Intro?start_param=${startParam}`)
    } else {
      navigate('/Intro')
    }
  }

  // Trigger authentication when user is available
  useEffect(() => {
    if (isUserAvailable) {
      handleUserAuthentication()
    }
  }, [isUserAvailable, telegramUser])

  return (
    <div className="app-container">
      <OrionGalacticLogo />
      <div className="logo-container">
        <img
          src="/assets/img/orion.svg"
          alt="Orion Galactic Logo"
          className="logo-image"
        />
      </div>

      {/* Error or Welcome Message */}
      <div 
        className="error-container" 
        style={{ 
          textAlign: 'center', 
          zIndex: 9999, 
          color: 'white' 
        }}
      >
        {(userError) ? (
          <>
            <p style={{ fontSize: '18px', margin: 0 }}>
              Oops! Something went wrong!
            </p>
            <p style={{ fontSize: '23px', margin: 0 }}>
              {userError || 'Invalid Telegram User'}
            </p>
          </>
        ) : (
          <>
            <p style={{ fontSize: '18px', margin: 0 }}>
              Welcome To Orion!
            </p>
            <p style={{ fontSize: '23px', margin: 0 }}>
              "Dream Big, Achieve Bigger with Orion Airdrop!"
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default AppContent