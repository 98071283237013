import React, { useState, useEffect } from "react";
import "../App.css";

function BottomNavigation({ setCurrentPage, initialSelectedItem }) {
  const [selectedItem, setSelectedItem] = useState(initialSelectedItem || "page1");

  useEffect(() => {
    localStorage.setItem("selectedItem", selectedItem);
  }, [selectedItem]);

  // پیش‌بارگذاری تصاویر
  useEffect(() => {
    const images = [
      "/assets/img/home-smile.svg",
      "/assets/img/coinearnselected.svg",
      "/assets/img/task.svg",
      "/assets/img/taskselected.svg",
      "/assets/img/friendsbottom.svg",
      "/assets/img/friendsbottomselected.svg",
      "/assets/img/newwallet.svg",
      "/assets/img/newwalletselected.svg",
    ];
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const handleItemClick = (page) => {
    if (selectedItem !== page) {
      setSelectedItem(page);
      setCurrentPage(page);
    }
  };

  const navItems = [
    {
      id: "page1",
      text: "Home",
      icon: "/assets/img/home-smile.svg",
      selectedIcon: "/assets/img/home-smile-selected.svg",
      alt: "Home",
      objectFit: "fill",
    },
    {
      id: "page2",
      text: "Earn",
      icon: "/assets/img/coinern.svg",
      selectedIcon: "/assets/img/coinearnselected.svg",
      alt: "Earn",
      objectFit: "fill",
    },
    {
      id: "page3",
      text: "Friends",
      icon: "/assets/img/friendsbottom.svg",
      selectedIcon: "/assets/img/friendsbottomselected.svg",
      alt: "Friends",
      objectFit: "fill",
    },
    {
      id: "page4",
      text: "Wallet",
      icon: "/assets/img/newwallet.svg",
      selectedIcon: "/assets/img/newwalletselected.svg",
      alt: "Wallet",
      objectFit: "fill",
    },
  ];

  return (
    <div className="bottom-navigation">
      {navItems.map((item) => (
        <div
          key={item.id}
          className={`nav-item ${selectedItem === item.id ? "selected" : ""}`}
          onClick={() => handleItemClick(item.id)}
        >
          <div className="icon-container" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}>
            <img
              src={item.icon}
              alt={`${item.alt} icon`}
              style={{
                objectFit: item.objectFit,
                display: selectedItem === item.id ? 'none' : 'block',
              }}
            />
            <img
              src={item.selectedIcon}
              alt={`${item.alt} selected icon`}
              style={{
                objectFit: item.objectFit,
                display: selectedItem === item.id ? 'block' : 'none',
              }}
            />
            <span>{item.text}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BottomNavigation;