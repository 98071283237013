import { supabase } from '../utils/supabaseClient';

class UserService {
  // ورود کاربر
  static async signIn(username, password) {
    try {
      if (!username || !password) {
        throw new Error('Username and password are required.');
      }
      const email = `${username}@OrionCoin.one`;
      console.log('Signing in with:', { email, password });
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });
      console.log('Supabase sign in result:', { data, error });
      if (error) {
        console.error('Supabase sign in error:', error);
        throw new Error(error.message);
      }
      return { data };
    } catch (error) {
      console.error('Sign in method error:', error);
      throw new Error(error.message);
    }
  }

  static async signUp(username, password, name, tgusername, tgid, inviteid, wallet, coin, intro) {
    try {
      if (!username) {
        throw new Error('Username is required.');
      }
      const email = `${username}@orioncoin.one`;
      console.log('Signing up with:', { email, username, name, tgusername });
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });
      console.log('Supabase sign up result:', { data, error });
      if (error) {
        console.error('Supabase sign up error:', error);
        throw new Error(error.message);
      }
      const { error: insertError } = await supabase
        .from('users')
        .insert([{
          id: data.user.id,
          email: email,
          username: username,
          name: name,
          tgusername: tgusername,
          tgid: tgid,
          inviteid: inviteid,
          wallet: wallet,
          coin: coin,
          intro: false,
        }]);
      console.log('User insert result:', { insertError });
      if (insertError) {
        console.error('User insert error:', insertError);
        throw new Error(insertError.message);
      }
      return { data };
    } catch (error) {
      console.error('Sign up method error:', error);
      throw new Error(error.message);
    }
  }

  static async getSession() {
    try {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error getting session:', error);
        return { data: null, error: error.message };
      }
      console.log('Session data:', data);
      return { data: data.session, error: null };
    } catch (err) {
      console.error('Unexpected error in getSession:', err);
      return { data: null, error: err.message };
    }
  }

  static async getUserDetails() {
    try {
      // Get current session
      const { data: { session } } = await supabase.auth.getSession();

      if (!session) {
        throw new Error('No active session');
      }

      // Log session user details
      console.log('Session User id:', session.user.id);

      // Fetch user details using id instead of email
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (error) {
        console.error('Supabase query error:', error);
        throw error;
      }

      if (!data) {
        throw new Error('No user data found for this user');
      }

      return data;
    } catch (error) {
      console.error('Complete getUserDetails error:', error);
      throw error;
    }
  }

  static async getUserDetailsByTgid(tgid) {
    try {
      if (!tgid) {
        throw new Error('Telegram ID (tgid) is required.');
      }
      
      console.log('Fetching user details for Telegram ID:', tgid);

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('tgid', tgid)
        .single();

      if (error) {
        console.error('Supabase query error:', error);
        throw new Error('Error fetching user details.');
      }

      if (!data) {
        throw new Error('No user found for this Telegram ID.');
      }

      return data;
    } catch (error) {
      console.error('Complete getUserDetailsByTgid error:', error);
      throw error;
    }
  }

  static async getUserDetailsByInvitedId(inviteid) {
    try {
      if (!inviteid) {
        throw new Error('inviteid is required.');
      }
      
      console.log('Fetching user details for inviteid:', inviteid);

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('inviteid', inviteid)
        .single();

      if (error) {
        console.error('Supabase query error:', error);
        throw new Error('Error fetching user details.');
      }

      if (!data) {
        throw new Error('No user found for this Telegram ID.');
      }

      return data;
    } catch (error) {
      console.error('Complete getUserDetailsByTgid error:', error);
      throw error;
    }
  }

  // خروج از سیستم
  static async signOut() {
    await supabase.auth.signOut();
  }

  // رفرش کردن توکن در صورت منقضی شدن
  static async refreshSession() {
    const session = await supabase.auth.getSession();
    if (session && session.refresh_token) {
      const { data, error } = await supabase.auth.refreshSession(session.refresh_token);
      if (error) {
        throw new Error('Error refreshing session');
      }
      return data;
    }
    throw new Error('No refresh token available');
  }

  static initAuthListener() {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
        // کاربر از سیستم خارج شده یا حذف شده است
      }
      if (session) {
        console.log('User session:', session);
      } else {
        try {
          await UserService.refreshSession();
        } catch (error) {
          console.error('Error refreshing session:', error);
        }
      }
    });
  }

  // دریافت اطلاعات ذخیره‌شده کاربر از getSession
  static async getStoredUserData() {
    const { data, error } = await supabase.auth.getSession();
    if (data && data.user) {
      return data.user;  // اطلاعات کاربر ذخیره‌شده در session
    }
    return null;
  }

  static async updateUserDetails(updatedData) {
    try {
      const { data: { session } } = await supabase.auth.getSession();

      if (!session) {
        throw new Error('No active session');
      }

      const { data, error } = await supabase
        .from('users')
        .update(updatedData)
        .eq('id', session.user.id)
        .select();

      if (error) {
        console.error('Update Error:', error);
        throw error;
      }
      console.log(' user update shoe' + data.username)

      return data;
    } catch (error) {
      console.error('Complete Error:', error);
      throw error;
    }
  }

  static async updateUserDetailsId(updatedData , id) {
    try {
      const { data: { session } } = await supabase.auth.getSession();

      if (!session) {
        throw new Error('No active session');
      }

      const { data, error } = await supabase
        .from('users')
        .update(updatedData)
        .eq('id', id)
        .select();

      if (error) {
        console.error('Update Error:', error);
        throw error;
      }
      console.log(' user update shoe' + data.username)

      return data;
    } catch (error) {
      console.error('Complete Error:', error);
      throw error;
    }
  }


  static async getTopUsersByCoin(limit = 100) {
    try {
      // درخواست برای دریافت 100 کاربر اول مرتب شده بر اساس coin
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .order('coin', { ascending: false })  // ترتیب نزولی براساس coin
        .limit(limit);  // محدود کردن به 100 کاربر
  
      if (error) {
        console.error('Error fetching users:', error);
        throw new Error('Error fetching users');
      }
  
      // اگر داده‌ها پیدا نشدند
      if (!data || data.length === 0) {
        console.log('No users found.');
        return [];
      }
  
      console.log('Fetched top users by coin:', data);
      return data;  // بازگرداندن لیست کاربران
    } catch (error) {
      console.error('Error in getTopUsersByCoin method:', error);
      throw new Error(error.message);
    }
  }

}


export default UserService;
