import React, { useState } from 'react'
import '../css/BottomSheet.css'
import UserService from '../service/UserService'

const InviteLinkManager = () => {
  const [newInviteLink, setNewInviteLink] = useState('')
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false)

  // ایجاد لینک دعوت
  const generateInviteLink = async () => {
    try {
      const userData = await UserService.getUserDetails()
      const link = `https://t.me/OrionCryptoBot/Orion?startapp=${userData.inviteid}`
      setNewInviteLink(link) // پس از ایجاد لینک، وضعیت 'bottom sheet' به طور مستقیم تغییر می‌کند
      setIsBottomSheetVisible(true) // نمایش 'bottom sheet'
    } catch (error) {
      console.error('Error generating invite link:', error)
    }
  }

  // کپی کردن لینک دعوت به کلیپ‌بورد
  const copyToClipboard = (inviteLink) => {
    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        setIsBottomSheetVisible(false) // بعد از کپی، 'bottom sheet' بسته می‌شود
      })
      .catch((err) => {
        setIsBottomSheetVisible(false)
        console.error('Error copying text: ', err)
      })
  }

  function shareToTelegram(inviteLink) {
    if (window.Telegram && window.Telegram.WebApp) {
      const message = encodeURIComponent(
        `Orion is ready to shine! 🚀\n${inviteLink}`
      )
      const forwardLink = `https://t.me/share/url?url=${message}`
      window.location.href = forwardLink
      console.log('Forward link opened!')
      setIsBottomSheetVisible(false) // بستن پنجره
    } else {
      console.error('Telegram WebApp not found')
      setIsBottomSheetVisible(false) // بستن پنجره در صورت عدم وجود WebApp
    }
  }
  const toggleBottomSheet = () => {
    setIsBottomSheetVisible((prevState) => !prevState)
  }

  return (
    <div>
      <button className="button" onClick={generateInviteLink}>
        <img
          src="/assets/img/adduser.svg"
          alt="Community Icon"
          className="button-icon"
        />
        Invite
      </button>

      {isBottomSheetVisible && (
        <div className={`backdrop ${isBottomSheetVisible ? 'open' : ''}`}>
          <div className={`bottom-sheet ${isBottomSheetVisible ? 'open' : ''}`}>
            <div className="close-button" onClick={toggleBottomSheet}>
              &times;
            </div>
            <div className="bottom-sheet-content">
              <span class="invite-title-container">Invite</span>{' '}
              <button
                className="generate-button-share"
                onClick={() => shareToTelegram(newInviteLink)}
              >
                Share In Telegram
              </button>
              <button
                className="generate-button-share"
                onClick={() => copyToClipboard(newInviteLink)}
              >
                Copy Invite Link
              </button>


            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default InviteLinkManager
