import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom' // اضافه کردن useNavigate برای ناوبری
import '../css/Rank.css'
import UserService from '../service/UserService'
import OrionGalacticLogo from '../components/SkyBackground'

const Rank = () => {
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate() // استفاده از useNavigate

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true)
      try {
        const topUsers = await UserService.getTopUsersByCoin()
        setUsers(topUsers)
      } catch (err) {
        console.error('Error fetching users:', err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchUserData()
  }, [])

  const NumberWithCommas = ({ number }) => {
    const formattedNumber = new Intl.NumberFormat().format(number)
    return <div className="coin">{formattedNumber}</div>
  }

  return (
    <div>
      <OrionGalacticLogo />

      {isLoading ? (
        <div>Loading...</div>
      ) : (
        // بخش قبل از users.map
        <div className="user-rank-wrapper">
          {/* این بخش را قبل از map قرار می‌دهیم */}
          <div className="orion-logo-wrapper" style={{ marginTop: '0px' }}>
            <div className="orion-logo"></div>

            <div
              className="rank-text-wrapper"
              style={{ marginTop: '-10px', marginBottom: '20px' }}
            >
              <img
                src="/assets/img/rank.svg"
                alt="Rank Icon"
                className="ranks-icon"
              />
              <span className="ranks-text">Leaderboard</span>
            </div>
          </div>

          {/* حالا لیست کاربران را با map نمایش می‌دهیم */}
          {users.map((user, index) => (
            <button key={index} className="ranks-buttons">
              <span className="coin" style={{marginLeft: '2px' , marginRight: '4px' , color: 'gray'}}> #{index+1}</span> {/* اضافه کردن شماره اندیس */}
              {/* <img
                src="/assets/img/orion.svg"
                alt="Icon"
                className="button-icon"
              /> */}
              <span className="coin" style={{ marginRight: 'auto' }}>
                {user.tgusername}
              </span>
              <NumberWithCommas number={user.coin }  /> <p style={{fontSize: '12px' , marginLeft: '4px'}}> ORION </p>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default Rank
